import styled from "styled-components";

export const TitleH2 = styled.h2`
  opacity: 1;
  letter-spacing: -1px;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 20px;
  &.font-Oswald{
    font-family: Oswald;
  }
  .value {
    svg {
      width: 100%;
    }
  }
  .good {
    svg {
      width: 100%;
    }
  }
  .svz {
    width: 100%;
  }
  .MagentoDesignLine {
    svg {
      width: 100%;
    }
  }
  @media screen and (min-width: 1440px) {
    letter-spacing: -1.5px;
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 57px;
    line-height: 67px;
    font-weight: 700;
  }

  @media screen and (max-width: 1024px) {
    &.EngagementTitel {
      font-size: 42px;
      line-height: 52px;
    }
    &.ExploreServiceTitle {
      font-size: 39px;
      line-height: 50px;
      margin-bottom: 20px;
    }
    &.MicroservicesTechnologies{
      font-size: 35px !important;
      line-height: 45px !important;
    }
  }
  
  @media screen and (max-width: 991px) {
    font-size: 33px;
    line-height: 45px;
    opacity: 1;
    letter-spacing: -1px;

    .BackEndSvg {
      top: -25px !important;
    }
    .Richfluid {
      display: none;
    }
    .fullsizesvg {
      right: -45px;
      bottom: -11px;
      svg {
        width: 70%;
      }
    }
    &.EngagementTitel {
      font-size: 33px;
      line-height: 43px;
    }
    &.ManufacturingServiceTitle {
      font-size: 32px;
      line-height: 42px;
    }
    &.ExploreServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
    &.MicroservicesTechnologies{
      font-size: 25px !important;
      line-height: 35px !important;
    }
  }
  @media screen and (max-width: 778px) {
    letter-spacing: -1px;
    width: 100%;
    font-weight: 700;
    margin-bottom: 24px;

    &.ManufacturingServiceTitle {
      font-size: 30px;
      line-height: 40px;
    }
  }
  @media screen and (max-width: 767px) {
    br {
      display: none;
    }
  }

  @media screen and (max-width: 736px) {
    &.ExploreServiceTitle {
      font-size: 23px;
      line-height: 33px;
    }
  }
  @media screen and (max-width: 479px) {
    letter-spacing: -0.5px;
    font-size: 25px;
    line-height: 35px;
    .fullsizesvg {
      right: -25px;
      bottom: -20px;
      svg {
        width: 70%;
      }
    }
    br {
      display: none;
    }
    .BackEndSvg {
      top: -20px !important;
    }
    .partnerships {
      svg {
        width: 70%;
      }
    }
    .fullsizesvg {
      svg {
        width: 50%;
      }
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
    &.ManufacturingServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.ExploreServiceTitle {
      font-size: 25px;
      line-height: 35px;
    }
  }

  @media (min-width: 390px) and (max-width: 415px) {
    .partnerships {
      right: -2rem;
    }
  }
  @media (min-width: 380px) and (max-width: 390px) {
    font-size: 23px;
    line-height: 32px;
    &.GenerateTitle {
      font-size: 25px;
      line-height: 35px;
    }
    &.EngagingTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
  @media (min-width: 350px) and (max-width: 379px) {
    font-size: 22px;
    line-height: 32px;
    &.EngagingTitle {
      font-size: 23px;
      line-height: 33px;
    }
    &.GenerateTitle {
      font-size: 24px;
      line-height: 34px;
    }
  }
`;

export const TitleMain = styled.div`
  text-align: center;
  max-width: 800px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media screen and (min-width: 1440px) {
    max-width: 1050px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 991px) {
    max-width: 700px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 778px) {
    max-width: 600px;
  }
  @media screen and (max-width: 479px) {
    max-width: 370px;
    margin-bottom: 30px;
  }
`;

export const TitleP = styled.p`
  text-align: center;
  letter-spacing: -0.2px;
  margin-top: 0;
  font-size: 20px;
  line-height: 32px;
  display: inline-table;
  margin-left: 0;
  margin-right: 0;
  max-width: 900px;

  @media screen and (min-width: 1440px) {
    letter-spacing: -0.2px;
    margin-top: 0;
    font-size: 26px;
    line-height: 42px;
  }
  @media (max-width: 1025px) {
    margin-top: 0;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 991px) {
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 26px;
    max-width: 530px;
    margin: 0 auto;
  }
  @media screen and (max-width: 778px) {
    letter-spacing: 0.1px;
    br {
      display: none;
    }
  }
  @media screen and (max-width: 479px) {
    font-size: 14px;
    line-height: 22px;
    margin-top: 0px;

    br {
      display: none;
    }
  }
`;

export const AIMLList = styled.ul`
  letter-spacing: -0.26px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 16px;
  line-height: 24px;
  list-style-type: none;
  overflow: visible;
  &.form-list {
    font-size: 16px;
    line-height: 24px;
      li {
        margin-bottom:20px;
        &:before {
          top: 0px;
          bottom: 0px;
          margin: auto;
          left: 0;
          width: 19px;
          height: 13px;
          filter: invert(0) brightness(1);
        }
      }
  }
  &.feel-list {
    font-size: 26px;
    line-height: 36px;
    margin-left: 35px;
    li {
      display: flex;
      column-gap: 15px;
      margin-bottom: 20px;
      align-items: baseline;
    }
    ul {
      li {
        &:before {
          top: 0px;
          bottom: 0px;
          margin: auto;
          left: 0;
          width: 19px;
          height: 13px;
          filter: invert(0) brightness(10);
          //background: url(https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg) center center no-repeat;
        }
      }
    }
  }
  &.perfect {
    font-size: 26px;
    line-height: 36px;
    margin-left: 35px;
    li {
      display: flex;
      column-gap: 15px;
      margin-bottom: 20px;
    }
    ul {
      li {
        &:before {
          top: 0px;
          bottom: 0px;
          margin: auto;
          left: 0;
          width: 19px;
          height: 13px;
          filter: invert(0) brightness(1);
          //background: url(https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg) center center no-repeat;
        }
      }
    }
  }
  ul {
    list-style: unset;
    padding-top: 10px;
    li {
      padding: 0px 0px 0px 35px;
      list-style: none;
      position: relative;
      margin-bottom: 20px;
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        width: 19px;
        height: 13px;
        ${
          "" /* background: url(https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/right-arrow-orange.svg) center center no-repeat; */
        }
        filter: invert(1) brightness(0);
        background-size: cover;
      }
    }
  }
  @media (min-width: 1440px) {
    letter-spacing: -0.26px;
    font-size: 16px;
    line-height: 24px;
    ul {
      li {
        padding: 0 0 0 35px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &.perfect {
      font-size: 16px;
      line-height: 26px;
    }
    &.feel-list {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 991px) {
    letter-spacing: 0;
    font-size: 16px;
    line-height: 26px;
    ul {
      li {
        padding: 0px 0px 0px 35px;
      }
    }

    &.perfect {
      font-size: 16px;
      line-height: 26px;
    }
    &.feel-list {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media (max-width: 600px) {
    ul {
      /* padding-left: 1rem; */

      li {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        margin-top: 1rem;
      }
    }
  }
`;

export const PerfectMatchCard = styled.div`
  border-radius: 16px;
  padding: 48px 42px;
  background: var(--background-color-dark);
  border-radius: 16px;

  .innerArraw {
    svg {
    }
  }
  &.card-right {
    border: 0px solid var(--text-color-dark);
  }
  &.card-left {
    color: var(--text-color);
  }
  @media (max-width: 991px) {
    padding: 28px;
  }
`;

export const PerfectMatchCardInner = styled.div`
  letter-spacing: -0.2px;
  font-size: 20px;
  line-height: 32px;
  display: block;
  @media screen and (min-width: 1440px) {
    letter-spacing: -0.2px;
    font-size: 26px;
    line-height: 42px;
  }
  @media screen and (max-width: 991px) {
    letter-spacing: -0.2px;
    font-size: 16px;
    line-height: 26px;
  }
  @media screen and (max-width: 767px) {
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 479px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

export const PerfectMatchCol = styled.div`
  &.col-right {
    justify-content: flex-end;
    flex-flow: column;
    display: flex;
    //left: -45px;
    margin-left: -45px;
    position: relative;
  }
  @media (max-width: 1024px) {
    margin-left: 0px !important;
  }
  @media (max-width: 600px) {
    &.col-right {
      margin-left: 0px;
    }
  }
`;

export const PerfectMatchIcon = styled.div`
  &.feelsvg {
    padding-right: 80px;
  }
`;

export const PerfectMatchMain = styled.div`
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

export const ScaleButton = styled.div`
  max-width: max-content;
  padding: 10px 15px;
  border-radius: 7px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 12px;
  letter-spacing: 0.09em;

  &.getButton {
    background: #ff8e5e;
    // color: #1d1e22;
  }

  @media (max-width: 1024px) {
    font-size: 13px;
  }

  @media (max-width: 769px) {
    font-size: 14px;
    margin-top: 1rem;
  }
  @media (max-width: 430px) {
    &.EngagementModel-btn {
      margin: 0 auto;
    }
  }
`;

export const StrugglingMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;

  @media (max-width: 1024px) {
    &.custom-app {
      padding: 4rem 0;
    }
    &.portfolioMobile {
      padding: 3rem 0;
    }
    &.before {
      padding: 0rem 0;
    }
  }
  @media (max-width: 991px) {
    &.custom-app {
      padding: 2rem 0;
    }
  }
  @media (max-width: 820px) {
    /* padding: 5rem 0; */
    &.CareersstrugglingMain {
      padding-bottom: 9rem !important;
    }
  }
  @media (max-width: 768px) {
    padding: 0rem 0;
    &.CareersstrugglingMain {
      padding-bottom: 7rem !important;
    }
  }

  @media (max-width: 479px) {
    margin-bottom: 30px;
    &.CareersstrugglingMain {
      padding-bottom: 9rem !important;
    }
  }
  @media (max-width: 430px) {
    /* margin-top: 3rem; */
    /* padding: 1rem 0 !important; */
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    &.before {
      padding: 7rem 0;
    }
    &.frontend {
      margin-top: 4rem;
      margin-bottom: 1rem;
    }
    &.BusinessNeedsSliderMain {
      margin-top: 0rem;
    }
  }
`;